
ul {
    margin-top: 0;
    margin-bottom: 0 !important;
    list-style-type: none;
    overflow: hidden;
}

li {
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    float: right;
}

/* .NavBar {
    height: 10vh;
} */