body, html {
  height: 100%;
  margin: 0;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
} */

.Centered-title {
  height: 100vh;
  position: relative
}

.Centered-title h1 {
  margin: 0;
  font-size: 10vw;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-family: auto;
  font-style: italic;
}

.item {
  width: inherit;
  height: inherit;
  background: white;
  transform-origin: 50% 100%;
}

.buttonWrapper {
  display: inline-block;
}

.Main-content {
  /* background-color: #f5f6fc; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  /* padding-bottom: 50px; */
  font-size: 1em;
}

.App-link {
  color: #61dafb;
}

p {
  margin: 0px;
}

.progress-icon {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: 120px;
}

.Form {
  width: 50vw;
  margin-top: 25px;
}

.ModalForm {
  /* width: 50vw; */
}

.Form-Post {
  float: right;
}

.List-delete-icon {
  float: right;
  font-size: 30px;
  padding-bottom: 10px;
}

Button {
  border-color: #29338f !important;
  background-color: #293e8f !important;
}

.User-list-item {
  text-align: left;
  width: 50vw;
  border-radius: 15px;
  background: white;
  margin: 5px;
  margin-top: 15px;
  padding: 10px 20px 10px 20px;
  list-style-type: none;
  box-shadow: 5px 10px #97b2ee;
  position: relative;
}

.List-Content {
  display: flex;
  position: relative;
}

.Check-list-section {
  /* border: 2px dashed hotpink; */
  width: 10%;
  float: left;
  position: relative;
  display: grid;
}

.Check-item {
  justify-self: start;
  align-self: center;
  height: 25px;
  width: 25px;
}

.Text-list-section {
  /* border: 2px dashed blue; */
  width: 80%;
  float: left;
  position: relative;
}

.Title-item {
  /* border: 2px dashed red; */
  font-size: 1.25em;
  overflow-wrap: normal;
}

.Title-completed {
  font-size: 1.25em;
  overflow-wrap: normal;
  text-decoration: line-through;
}

.Description-item {
  /* border: 2px dashed red; */
  padding-top: 5px;
  color: #6f6f70;
  overflow-wrap: normal;
}

.Description-completed {
  padding-top: 5px;
  color: #6f6f70;
  overflow-wrap: normal;
  text-decoration: line-through;
}

.Button-list-section {
  /* border: 2px dashed black; */
  width: 10%;
  float: left;
  position: relative;
  /* text-align: right; */
  display: grid;
}

.List-edit-icon {
  /* border: 2px dashed green; */
  width: 22px !important;
  height: 22px !important;
  justify-self: end;
  align-self: start;
  padding: inherit;
  color: #4ed4ac;
  grid-column: 1;
  grid-row: 1;
  opacity: 1;
}

.List-delete-icon {
  /* border: 2px dashed yellow; */
  justify-self: end;
  align-self: end;
  padding: inherit;
  color: #d44e5c;
  grid-column: 1;
  grid-row: 2;
}

.App-logo {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 10vmin;
  align-self: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .App-logo {
    margin-top: 20px;
    height: 20vmin;
    align-self: center;
  }
  .Form {
    width: 75vw;
  }
  .User-list-item {
    width: 75vw;
    margin: 0px auto;
    margin-top: 30px;
  }
  .Check-item {
    justify-self: start;
    align-self: center;
    height: 15px;
    width: 15px;
  }
  .progress-icon {
    visibility: hidden;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}