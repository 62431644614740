.containerStyle {
  position: relative;
  width: 3rem;
  height: 3rem;
}

.circleStyle {
  display: block;
  width: 3rem;
  height: 3rem;
  border: 0.5rem solid white;
  border-top: 0.5rem solid #293e8f;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

/* div {
  background-color: hotpink;
} */
